import './Nav.css'

function Nav() {

    return (
        <>
            <div class="navigation">
            <nav>
                <a target="_blank" class="links"href="https://github.com/aadidapurkar">github</a>
                <a target="_blank" class="links" href="mailto:hi@aadi.au">email</a>
                <a target="_blank" class="links" href="https://aadi.au/resume.pdf">resume</a>

            </nav>
            </div>


        </>
    )
}

export default Nav;