import './Projects.css'
function Projects() {

    const projects = {
        // "Rude Debaters": {link: "https://interrupt.aadi.au", tech: ["Python","Neural Networks","Flask","React.JS","Firebase"], info: "It's hard to watch a debate or interview in which someone is constantly interrupted. This project aims to highlight debates in which a party was rude and interjected a lot. This was achieved through finding sound bites of many debates, and passing them through an open source model that identifies different voices. By judging based on the amount of time someone waited before replying, I classified 'interruptions'. Users can upload their own sound bites along with browsing already parsed data."},

        "Essay Feedback": {link: "https://essayfeedback.xyz", tech: ["Node.JS","Google Cloud", "Firebase"], info: "I created a website where students were able to submit their essays for feedback from others, as well as giving feedback to others. I used this project to help  my understanding of NodeJS for back end and used Vanilla HTML/CSS/JS for the front end. I had to learn about serverless frameworks which allowed me to host a back end so that it can serve content and also store content in a database, I also was introduced to the idea of asynchronous requests"},

        "Rare Available Number Plate Finder": {link: "https://github.com/aadidapurkar/VPlates-Checker/blob/main/Rare_Plates_Project_Aadi_Dapurkar.pdf", tech: ["Python","Web Scraping"], info: "Program that made requests to the Victorian custom numberplate website to search for available 3 letter plates. Initially, I was making very fast requests to the API but I encountered issues with rate limiting, this was a learning experience that allowed me to learn a bit about proxies and parallelizing requests. I ended up buying one of the plates the program found. If interested, you can click 'View More' to find the list of available 3 letter Victorian plates I found. (Some might be taken though, as I did this project in 2021)"},

        "Identifying Vulnerable Companies/Websites": {link: "View more", tech: ["AWS","Python","Web Scraping"], info: "In case you are unaware, .AU domains  became available for the public to purchase. I identified this as a vulnerability for companies who failed to register their domains with priority. Using Common Crawl datasets, I parsed a list of all Aussie websites that its scraper encountered. For each website, I checked whether it had registed its corresponding .AU domain or not. I would have liked to somehow send mass emails to the affected sites, or make profit off parking popular domains but I encountered issues such as how to not get filtered for spam, and the legality of registering copyrighted businesses' domains. But ultimately, this was useful to help me learn a bit about scraping data. "},
        
        "Macroaus": {link:"", tech: ["Node.JS","Python","React"], info: "I scraped nutritional information from woolworths and coles products, and used it to create an application which allowed people to track the calories in the food they ate with ease, with the program calculating the calories solely based off the item and amount rather than the person having to look up the info and then perform calculations to get the values of each macronutrient etc... This involved using Oracle Cloud servers to scrape data using a Python script I made, creating a front end for the application with React, and then making queries from that front end to a back end where I handled requests for particular products etc. This project overall helped me become more acquainted with making a full stack application."},

        "Spotify Friends Activity for Soundcloud":{link: "View more", tech: ["React","Node","Chrome Extension"], info: "I created a chrome extension that emulates Spotify's feature where users can see what songs their friends are listening to. "},


    }

    return (
        <section className="allProjects">
            {
                Object.keys(projects).map(function(key,index) {
                    console.log(key)
                    console.log(projects[key].link)
                    console.log(projects[key].tech)
                    console.log(projects[key].info)
                    return (
                    <>
                    <div className='project'>
                    <h1>Projects</h1>
                    <p className='projTitle'>{key}</p>
                    <a className='projLink' href={projects[key].link}>View</a>
                    <div className='techStack'>
                    {
                        projects[key].tech.map(function(typeOfTech) {
                            return(
                                <>

                                    <span id="stacks">{typeOfTech}</span>
                                </>
                            )
                        })
                    }
                    </div>
                    <p className='projDesc'>{projects[key].info}</p>     
                    </div>               
                    </>
                    )
                })
                
            }
        </section>
    )
}




export default Projects;
