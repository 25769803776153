import './App.css';
import Nav from './components/Nav.jsx';
import Projects from './components/Projects.jsx'
function App() {
  return(<>
    <div class="container">
    <Nav />
    <h1>Aadi Dapurkar</h1>

    <div className='skills'>
    <p><strong>Front end:</strong> HTML, CSS, React JS</p>  
    <p><strong>Back end: </strong> Node JS, Flask</p>
    <p><strong>Misc: </strong>Git, Github, AWS, Google Cloud Platform </p>
    </div>

    <div className="skills" id="info">
      <p>I am a CS student at Monash. I am currently trying to learn how to make full stack websites as I feel like this pursuit is one where I can really cause a direct impact on people, e.g implementing people's passions/dream ideas. </p>
    </div>
    <Projects />
    </div>
  </>)
}

export default App;
